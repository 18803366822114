@charset "UTF-8";

/* mypage */
.memtoList > div {
    max-width:25%;
}

.feedback {
    width:20px;
    height:20px;
    background:orangered;
    color:#fff;
    border-radius: 50%;
    display:block;
    right:-16px;
    top:-10px;
    text-align: center;
    line-height: 20px;
    font-size: 11px;
}

.mentoDetail .mentoInfo span{
    font-size:13px;
    color:#999;
    line-height:19px;
    height:20px;
    display:block;
    width:100%;
}

.mentoDetail .mentoInfo .infomation {
    padding:10px;
    border:1px solid #ddd;
    border-radius:3px;
    color:#333
}

.table .thead-blue th{
    background:#42a6d5;
    border:#42a6d5;
    color:#fff;
}