@charset "UTF-8";

/* 회원가입 입력 */
#register_form {
  background: #fff;
  margin-bottom: 20px;
}
#register_form h2 {
  padding: 20px;
  border-bottom: 1px solid #dde7e9;
}
.register_form_inner {
  background: #f7f7f7;
  border: 1px solid #dde7e9;
  border-radius: 3px;
}
.register_form_inner ul {
  padding: 20px;
}
.register_form_inner label {
  display: block;
  margin-bottom: 10px;
  line-height: 24px;
}

#fregisterform #msg_certify {
  margin: 5px 0 0;
  padding: 5px;
  border: 1px solid #dbecff;
  background: #eaf4ff;
  text-align: center;
}
#fregisterform .frm_address {
  margin: 5px 0 0;
}
#fregisterform #mb_addr3 {
  display: inline-block;
  margin: 5px 0 0;
  vertical-align: middle;
}
#fregisterform #mb_addr_jibeon {
  display: block;
  margin: 5px 0 0;
}
#fregisterform .btn_confirm {
  text-align: center;
}
#fregisterform .form_01 div {
  margin: 0 0 20px;
}
#fregisterform .captcha {
  display: block;
  margin: 5px 0 0;
}
#fregisterform .reg_mb_img_file img {
  max-width: 100%;
  height: auto;
}
#reg_mb_icon,
#reg_mb_img {
  float: right;
}

.register_head {
  margin-top: 100px;
  padding-bottom: 40px;
}
.register_head > * {
  margin-top: 24px;
}
.register_head .login {
  width: 80px;
  height: 80px;
  border: 1px solid #f4f5f4;
  background: #f4f5f4;
  border-radius: 50%;
  text-align: center;
  color: #666;
  margin-top: 0px;
}
.register_head .bar {
  width: 40px;
  height: 2px;
  background: #666;
}
#fregister p {
  background: none;
  font-weight: normal;
  font-size: 15px;
  color: #666;
}
#fregister p:before {
  background: none;
}

.resi_btn_Wrap {
  border-left: 1px solid #999;
}
.resi_btn {
  border: 1px solid #999;
  padding: 6px 10px;
  border-radius: 0px;
  border-left: 0px;
}
.register_form_inner label {
  margin-bottom: 0px;
  font-size: 13px;
  color: #666;
}
.mento_form{
  display:none; 
}
#kind1:checked ~ .register_form_inner .kind1.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#kind1:checked ~ .mento_form{
  display:none;
}#kind2:checked ~ .mento_form {
  display:block;
}
#kind2:checked ~ .register_form_inner .kind2.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_5_1:checked ~ .reg_mb_5_1.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_5_2:checked ~ .reg_mb_5_2.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_6_1:checked ~ .reg_mb_6_1.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_6_2:checked ~ .reg_mb_6_2.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_6_3:checked ~ .reg_mb_6_3.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
#reg_mb_6_4:checked ~ .reg_mb_6_4.resi_btn {
  background: #033887;
  color: #fff;
  border-color: #033887;
}
/* #education1:checked ~ .education1.resi_btn{background:#033887; color:#fff; border-color:#033887}
#education2:checked ~ .education2.resi_btn{background:#033887; color:#fff; border-color:#033887}
#education3:checked ~ .education3.resi_btn{background:#033887; color:#fff; border-color:#033887}
#education4:checked ~ .education4.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major1:checked ~ .major1.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major2:checked ~ .major2.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major3:checked ~ .major3.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major4:checked ~ .major4.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major5:checked ~ .major5.resi_btn{background:#033887; color:#fff; border-color:#033887}
#major6:checked ~ .major6.resi_btn{background:#033887; color:#fff; border-color:#033887} */
#fregisterform .form_01 .career div.grid {
  padding-left: 0;
}
#addCareer {
  padding: 0px 4px;
  font-size: 13px;
  margin: 10px 0 !important;
}

#reg_result .reg_result_p {
  color: #033887;
}
.btn_confirm_reg a {
  font-size: 15px !important;
  font-weight: normal;
}
#notLogin {
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.5;
  padding: 8px 14px;
}

#reg_result .reg_result_p {
  color: #033887;
}
.btn_confirm_reg a {
  font-size: 15px !important;
  font-weight: normal;
}
#notLogin {
  font-size: 12.5px;
  font-weight: normal;
  line-height: 1.5;
  padding: 8px 14px;
}

/* 약관 */
.fregister_agree {
  position: absolute;
  top: 0;
  right: 0;
}
.fregister_agree input[type="checkbox"] + label {
  color: #676e70;
}
.fregister_agree input[type="checkbox"] + label:hover {
  color: #2172f8;
}
.fregister_agree input[type="checkbox"] + label span {
  position: absolute;
  top: 20px;
  right: 15px;
  width: 17px;
  height: 17px;
  display: block;
  background: #fff;
  border: 1px solid #d0d4df;
  border-radius: 3px;
}
.fregister_agree input[type="checkbox"]:checked + label {
  color: #000;
}
.fregister_agree input[type="checkbox"]:checked + label span {
  background: url("../../image/chk.png") no-repeat 50% 50% #3a8afd;
  border-color: #1471f6;
  border-radius: 3px;
}
.fregister_agree.chk_all input[type="checkbox"] + label span {
  top: 18px;
}
