@charset "UTF-8";

.minwidth {
  min-width: 1200px;
  min-height: 100%;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn {
  background-image: none !important;
}

.pt40 {
  padding-top: 40px;
}

.mt40 {
  margin-top: 40px;
}

.mt60 {
  margin-top: 60px;
}

.mt80 {
  margin-top: 80px;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb100 {
  margin-bottom: 100px;
}

.h1 {
  font-size: 2.5rem;
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.8rem;
}

.h4 {
  font-size: 1.5rem;
}

.h5 {
  font-size: 1.4rem;
}

.h6 {
  font-size: 1.25rem;
}

.resizeWrap {
  padding-left: 250px !important;
  min-height: 100vh;
}

.main {
  padding: 0 0 80px 0;
  padding-left: 250px;
  box-sizing: border-box;
  font-size: 15px;
}

.fullWrap {
  width: 100%;
}

.basicWrap {
  width: 1200px;
  margin: 0 auto;
}

.main .title {
  font-weight: bold;
  letter-spacing: -0.02rem;
  font-size: 18px;
}

.btn {
  height: inherit;
  padding: 8px 14px;
}

.btn-xs {
  padding: 3px 8px;
  font-size: 13px;
}

.squear-btn-basic,
a.squear-btn-basic {
  color: #fff;
  border-radius: 0px;
  background: #033887;
  border: 1px solid #033887;
}

.squear-btn-basic:hover {
  background: #fff;
  border: 1px solid #022b69;
  color: #033887;
}

.squear-btn-border-basic,
a.squear-btn-border-basic {
  color: #033887;
  border-radius: 0px;
  background: #fff;
  border: 1px solid #033887;
}

.squear-btn-border-basic.bgnone {
  background: transparent;
}

.squear-btn-border-basic:hover {
  background: #033887;
  border: 1px solid #022b69;
  color: #fff;
  text-decoration: none;
}

.squear-btn-border-basic:hover a,
.squear-btn-basic a {
  color: #fff !important;
  text-decoration: none;
}

.squear-btn-basic:hover a,
.squear-btn-border-basic a {
  color: #022b69 !important;
}

.squear-btn-basic a,
.squear-btn-border-basic a {
  display: block;
  width: 100%;
  height: 100%;
}

.radius {
  border-radius: 24px;
}

#main_banner {
  width: 100%;
  position: relative;
  background-color: #f3f6fa;
  /* background-image: url("../image/main/banner.png"); */
  background-repeat: no-repeat;
  background-position: 105% 100%;
  height: 600px;
  padding-left: 110px;
}

#main_banner h6 {
  color: #204ca0;
}

#main_banner a.btn {
  font-size: 15px;
  font-weight: normal;
}

#main_contents img {
  width: auto;
  height: auto;
  max-height: 100px;
  display: block;
  margin: 0 auto;
}

#main_contents > div {
  width: 33%;
}

#main_contents {
  width: 100%;
  padding-top: 80px;
}

#main_contents p {
  font-size: 15px;
  line-height: 1.8;
  word-break: keep-all;
  padding: 10px 20px;
}

#main_bn {
  border: 0;
  margin: 0;
}

#main_bn .btn_wr {
  display: none;
}

#main_bn .main_owl_pager {
  display: none;
}

@media screen and (min-width: 1921px) {
  #body {
    width: 1920px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 2460px) {
  .main {
    padding: 40px;
    margin-left: 0;
  }
}

/* 필수입력 */
.required,
textarea.required {
  background-image: url("../image/require.png") !important;
  background-repeat: no-repeat !important;
  background-position: right top !important;
}

/* 폼 테이블 */
.tbl_frm01 {
  margin: 0 0 20px;
}

.tbl_frm01 table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.tbl_frm01 th {
  width: 70px;
  padding: 7px 13px;
  border: 1px solid #e9e9e9;
  border-left: 0;
  background: #f5f8f9;
  text-align: left;
}

.tbl_frm01 td {
  padding: 7px 10px;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  background: transparent;
}

.wr_content textarea,
.tbl_frm01 textarea,
.form_01 textarea,
.frm_input {
  border: 1px solid #d0d3db;
  background: #fff;
  color: #000;
  vertical-align: middle;
  border-radius: 3px;
  padding: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

/* 회사소개 */
.row .w-100 img {
  width: 70%;
  display: block;
  margin: 0 auto;
}

.row .w-100 img.w60 {
  width: 60%;
}

.intro {
  letter-spacing: -0.32px;
}

.intro .t_img {
  display: block;
  height: 70px;
}

.intro h1 {
  color: #16284c;
  font-weight: bold;
  font-size: 36px;
  letter-spacing: -1px;
  margin-bottom: 40px;
}

.intro h3 {
  border-left: 3px solid #14449c;
  padding-left: 6px;
  line-height: 1;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.64px;
  color: #444;
}

.intro .intro2 .h3 {
  margin-bottom: 20px;
  border-left: 0;
  background: #16284c;
  color: #fff;
  padding: 8px 12px;
}

.intro .intro2 p {
  word-break: keep-all;
  padding-right: 15%;
  padding-left: 15%;
  margin-bottom: 10px;
  position: relative;
}

.intro .intro2 p:before {
  content: "-";
  display: inline-block;
  position: absolute;
  margin-left: -10px;
}

.intro3 h2 {
  color: #14449c;
  line-height: 1.5;
  letter-spacing: -0.64px;
}

.intro3 .program img {
  width: 80px;
  margin: 20px auto;
}

.intro3 .program > div {
  padding: 15px;
  border: 3px solid #eee;
  text-align: center;
}

.intro3 .program p {
  word-break: keep-all;
}

.intro3 .program > div:nth-child(even) {
  border: 0;
}

/* 오시는 길 */
#intro1_02 p,
#intro1_02 h3 {
  line-height: 2rem;
  font-size: 17px;
}

#intro1_02 span {
  color: #666;
}

/* background-image: url('../image/main/banner.png'); */

.mainImage {
  height: 100px;
  background-size: 100%;
  margin: 0 auto;
}

#main_contents div:nth-of-type(1) .mainImage {
  background-image: url("../image/main/icon01.jpg");
  width: 176px;
}

#main_contents div:nth-of-type(2) .mainImage {
  background-image: url("../image/main/icon02.jpg");
  width: 95px;
}

#main_contents div:nth-of-type(3) .mainImage {
  background-image: url("../image/main/icon03.jpg");
  width: 100px;
}
/*  */
.modal_mentee_mypage {
  position: absolute;
  top: 0;
  left: 0;
  /* display: block !important; */
  display: none;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1100;
}
.modal_mentee_mypage > div {
  background: white;
  width: 600px;
  height: 280px;
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -300px;
  margin-top: -140px;
}
.modal_mentee_mypage .btns {
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  /* height: 80px; */
  /* margin-top: 40px; */
}

.modal_mentee_mypage > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.modal_mentee_mypage .content {
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 55px;
  font-size: 18px;
}
