/* main_modal */
.main_modal h4 {
    font-size: 18px;
}

.main_modal h6 {
    font-size: 14px;
    margin-bottom: 0;
}

.main_modal .card-body {
    padding: 10px
}

.memtoListPopup .modal-body {
    padding: 40px 0
}

.modal-footer {
    border: 0px;
}

.modal-footer .btn {
    font-size: 0.85rem;
}