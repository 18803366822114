@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Jua&display=swap");
/* 초기화 */
*,
body,
html,
button,
input {
  font-family: "Noto Sans KR", "Nanum Gothic", sans-serif;
}

html {
  overflow-y: scroll;
}

html,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
img {
  margin: 0;
  padding: 0;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li,
dt,
dd,
ol {
  margin: 0;
  padding: 0;
}

ol {
  list-style-position: inside;
}

legend {
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999em;
  overflow: hidden;
}

label,
input,
button,
select,
img {
  vertical-align: middle;
}

button,
input[type="submit"] {
  cursor: pointer;
}

select {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
  word-break: break-all;
}

pre {
  overflow-x: scroll;
  font-size: 1.1em;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

h2 {
  font-size: 1.083em;
  font-weight: bold;
  margin: 10px 0;
}

#wrapper {
  min-height: 480px;
}

/* 관리자 */

#hd h1 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

#adm_top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: #6f6f6f;
  z-index: 1000;
}

#adm_top:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}

#adm_top #admlogo {
  background: #585858;
  height: 100%;
  float: left;
  width: 220px;
  padding: 15px 0 15px 0px;
  text-align: center;
}

#adm_top #admlogo a {
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}

#btn_gnb {
  display: none !important;
}

#btn_gnb.btn_gnb_open {
  width: 50px;
  height: 50px;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.btn_fixed_top {
  float: right;
}

.btn_fixed_top .btn {
  font-size: 13px;
}

#tnb {
  float: right;
  padding: 10px;
  border-bottom: 0;
}

#tnb ul:after {
  display: block;
  visibility: hidden;
  clear: both;
  content: "";
}

#tnb li {
  float: left;
  position: relative;
  margin-left: 3px;
}

#tnb a {
  color: #fff;
  padding: 0 10px;
  display: block;
  line-height: 30px;
}

#tnb .tnb_service {
  border-radius: 3px;
  background: #4e5eb7;
  color: #fff;
  padding: 0 10px;
  text-align: left;
  height: 30px;
  line-height: 30px;
}

#tnb .tnb_service:hover {
  background: #5969c3;
}

#tnb button {
  width: 100px;
  border: 0;
  border-radius: 3px;
  background: #4e5eb7;
  color: #fff;
  padding: 0 30px 0 10px;
  text-align: left;
  height: 30px;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#tnb button span {
  display: block;
  text-indent: -999px;
  overflow: hidden;
  width: 30px;
  border-radius: 3px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: #5969c3;
}

#tnb button:hover {
  background: #5969c3;
}

#tnb .tnb_mb_area {
  display: none;
  background: #5e6dc2;
  width: 100px;
  position: absolute;
  padding: 5px;
  margin-top: 1px;
}

#tnb .tnb_mb_area a {
  padding: 0;
}

#tnb .tnb_mb_area li {
  float: none;
}

#tnb .tnb_community {
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-indent: -999px;
  border-radius: 5px;
}

#tnb .tnb_community:hover {
  background-color: #2b3d9f;
}

#tnb .tnb_shop {
  width: 30px;
  height: 30px;
  overflow: hidden;
  text-indent: -999px;
  border-radius: 5px;
}

#tnb .tnb_shop:hover {
  background-color: #2b3d9f;
}

/*gnb*/
#gnb {
  position: fixed;
  top: 0;
  left: 0;
  max-width: 220px;
  height: 100%;
  padding-top: 50px;
  z-index: 999;
}

#gnb h2 {
  position: absolute;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}

#gnb .gnb_ul {
  position: relative;
  height: 100%;
  background: #ebebeb;
  width: 220px;
}

#gnb .gnb_li {
  border-bottom: 1px solid #ddd;
}

#gnb .gnb_li button {
  background-color: #ebebeb;
  border: 0;
  width: 220px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#gnb .gnb_li button span {
  padding-left: 10px;
  display: inline-block !important;
  position: static;
  width: auto;
  height: auto;
  overflow: visible !important;
  font-size: 16px;
  margin-left: 8px !important;
}

#gnb .gnb_li .btn_op {
  background: #ebebeb;
}

#gnb .on .btn_op {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-100 {
  background: #ebebeb;
}

#gnb .on .btn_op.menu-100 {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-200 {
  background: #ebebeb;
}

#gnb .on .btn_op.menu-200 {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-300 {
  background: #ebebeb;
}

#gnb .on .btn_op.menu-300 {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-400 {
  background: #ebebeb;
}

#gnb .on .btn_op.menu-400 {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-500 {
  background: #ebebeb;
  display: none;
}

#gnb .on .btn_op.menu-500 {
  background: #fff;
}

#gnb .gnb_li .btn_op.menu-900 {
  background: #ebebeb;
  display: none;
}

#gnb .on .btn_op.menu-900 {
  background: #fff;
}

#gnb .gnb_li button:hover {
  background-color: #f3f3f3;
}

#gnb .gnb_oparea {
  text-align: center;
  padding: 6px 0;
  background: #ddd;
}

#gnb .on .gnb_oparea {
  display: block;
}

#gnb .gnb_oparea h3 {
  display: none;
}

#gnb .gnb_oparea li {
  line-height: 28px;
}

#gnb .gnb_oparea li a {
  background-color: transparent;
  text-align: center;
  color: #555;
}

#gnb .gnb_oparea li a:hover,
#gnb .gnb_oparea li .on {
  color: #435ffe;
}

#gnb.gnb_small .gnb_oparea {
  display: none;
}

#gnb.gnb_small .gnb_li:hover .gnb_oparea {
  display: block;
}

.gnb_small .gnb_ul {
  display: block;
}

.adminMain ul li {
  width: 32%;
  padding: 20px;
  border-radius: 20px;
  background: #ddd;
  color: #fff;
  min-height: 200px;
  box-sizing: border-box;
}

.adminMain .list1 li:nth-child(odd),
.adminMain .list2 li:nth-child(even) {
  background: #cddde7;
}

.adminMain ul li a {
  display: block;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 18px;
  letter-spacing: -0.64px;
  font-weight: bold;
}

.adminMain ul li a i {
  font-size: 40px;
  opacity: 0.5;
  transition: 0.3s;
}

.adminMain ul li a h2 {
  margin-top: 22px;
  display: block;
  color: #333;
}

.adminMain ul li:hover a i {
  transform: rotateY(180deg);
  opacity: 1;
}

/*중간*/
#container {
  padding: 0 0 0 220px;
  margin-top: 100px;
  background: #fff;
  min-height: calc(100vh - 120px);
  min-width: 1200px;
  font-size: 14px;
  float: inherit;
  width: auto;
}

#container.container-small {
  padding-left: 50px;
}

#container_wr {
  padding: 25px;
  min-height: 500px;
}

#container_title {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  line-height: 50px;
  font-size: 1.5em;
  font-weight: bold;
  padding: 0 20px;
  padding-left: 240px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  z-index: 99;
  letter-spacing: -1px;
}

.btnArea.popbtn .btn {
  font-size: 13px;
  font-weight: normal;
  border-radius: 5px;
  background: #e8468a;
  color: #fff;
  padding: 4px 6px;
  height: 30px;
  margin-top: 0px;
  width: 40px;
}

.btnArea .btn.gray {
  background: #6f6f6f;
}

#container_title .btn {
  border-radius: 5px;
  background: #e8468a;
  color: #fff;
  padding: 4px 6px;
  font-size: 13px;
  height: 30px;
  margin-top: 0px;
}

#container_title .btn.gray {
  background: #6f6f6f;
}

#container_title .btn.black {
  background: #999;
}

#container_title div.float-right {
  height: 50px;
}

#container.container-small #container_title {
  padding-left: 70px;
}

.container_wr {
  padding: 20px;
  height: 100%;
}

.container_wr > div {
  width: 100%;
}

.searchArea input {
  padding: 6px;
  width: 400px;
  height: 38px;
}

.searchArea button,
.searchArea .button {
  width: 44px;
  height: 38px;
  color: #fff;
  background: #666;
  border: 0;
}

.tableStyle {
  width: 100%;
  font-size: 13px;
}

.tableStyle th {
  font-weight: bold;
  text-align: center;
}

.tableStyle td,
.tableStyle th {
  padding: 10px;
  vertical-align: middle;
}

.tableStyle span {
  display: block;
  margin-bottom: 6px;
  color: #666;
}

.tableStyle input[type="text"],
.tableStyle textarea {
  border: 1px solid rgb(190, 190, 190);
  padding: 6px;
}

.tableStyle label {
  margin-bottom: 0;
}

.tableStyle .banner_file {
  height: 520px;
  display: block;
  margin-top: 10px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 500px;
}

.tableStyle .banner_file img {
  width: auto;
  height: auto;
  max-width: 1200px;
  max-height: 480px;
}

.tableStyle .popup_input {
  width: 300px;
  font-size: 13px;
}

.tableStyle .popup_file {
  height: 200px;
  display: block;
  margin-top: 10px;
}

.tableStyle .popup_file img {
  width: auto;
  height: auto;
  max-width: 300px;
  max-height: 200px;
}

.tableStyle.bgTable {
  font-size: 13px;
}

.tableStyle.bgTable td,
.tableStyle.bgTable th {
  padding: 6px 4px;
}

.tableStyle.bgTable thead th {
  background: #033887;
  color: #fff;
}

.tableStyle.bgTable tr:nth-child(even) td {
  background: #f3f6fa;
}

.tableStyle.bgTable .reviseButton {
  display: inline-block;
  padding: 4px;
  background: #5e6dc2;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
}

.tableStyle.bgTable .reviseButton:hover {
  cursor: pointer;
}

.memberTable td,
.memberTable th {
  font-size: 13px;
}

.memberTable td {
  text-align: left;
}

.memberTable button {
  border-radius: 4px;
  background: #999;
  color: #fff;
  display: inline-block;
  padding: 4px 5px;
  border: 0;
}

/* .board p.title {max-width:500px; white-space: nowrap; overflow:hidden; text-overflow:ellipsis; height:100%;text-align:left;} */
.board p.title {
  text-align: left;
}
#container_title .btn.delete {
  margin-right: 10px;
}
.admBtn {
  padding: 10px 21px;
  border: 1px solid #033887;
  border-radius: 2px;
  display: inline-block;
  color: #033887;
  margin-right: 20px;
  cursor: pointer;
}

.admBtn:hover {
  background: #033887;
  color: white;
}
.admBtnSmall {
  padding: 5px 10px;
  border: 1px solid #f19292;
  border-radius: 2px;
  display: inline-flex;
  color: #f19292;
  margin-right: 20px;
  cursor: pointer;
  justify-self: flex-end;
  align-self: flex-end;
}
.admBtnSmall:hover {
  background: #f19292;
  color: white;
}
.modal_adm {
  position: absolute;
  top: 0;
  left: 0;
  /* display: block !important; */
  display: none;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  z-index: 1100;
}
.modal_adm > div {
  background: white;
  width: 400px;
  height: 280px;
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -200px;
  margin-top: -140px;
}
.modal_adm .btns {
  display: flex;
  justify-self: flex-end;
  align-self: flex-end;
  /* height: 80px; */
  /* margin-top: 40px; */
}

.modal_adm > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}
.modal_adm .content {
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 80px;
  font-size: 20px;
}
.memtoList {
  flex-wrap: wrap;
  width: 100%;
}
.memtoList div {
  margin-bottom: 40px;
}
/*  */
#gnb .gnb_oparea li li a {
  background-color: #ccc;
  font-size: 13px;
  text-align: left;
  padding-left: 80px;
}
