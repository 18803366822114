@charset "UTF-8";

/* leftmenu */
#logo {
  width: 100%;
  border-bottom: 1px solid #fff;
  float: inherit;
  padding: 0;
}

#logo a {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px 0px;
  min-height: 140px;
}

#logo img {
  display: block;
  width: 165px;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
}

#mySidenav.sidenav {
  background-color: #f3f6fa;
}

#left_menu {
  margin-top: 20px;
}

.sidenav .left_menu_li_1 {
  position: relative;
}

.sidenav .left_menu_a_1 {
  display: inline-block;
  font-size: 18px;
  color: #222;
  font-weight: 600;
}

.sidenav .left_menu_a_1 span {
  position: absolute;
  right: 0;
  top: 14px;
  margin-right: 12px;
  z-index: 10;
  padding: 0 20px;
  height: 40px;
  opacity: 0.8;
}

.sidenav .left_menu_a_1 span.cl {
  display: none;
  top: 24px;
}

.left_menu_2 {
  display: none;
}

.left_menu_2 .left_menu_li_2 {
  margin-top: 0px;
}

.left_menu_2 .left_menu_li:first-child {
  margin-top: 0px;
}

.left_menu_li_2,
.left_menu_li_3 {
  padding-left: 10px;
}

.sidenav a {
  padding: 4px 6px 4px 32px;
  text-decoration: none;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  display: block;
  transition: 0.3s;
}

.sidenav a.left_menu_a_1 {
  padding: 20px 6px 20px 32px;
}

.sidenav .closebtn {
  right: 20px;
  top: 10px;
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  opacity: 0.3;
}

.sidenav a:hover {
  color: #000;
}

.nav2 a {
  font-size: 14px;
  padding: 6px 6px 6px 32px;
}

.main {
  transition: margin-left 0.5s;
  margin-left: 0px; /* Same as the width of the sidenav */
}

.sidenav .chkbox button {
  width: calc(100% - 64px);
  border-width: 1px;
  font-weight: bold;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

@media screen and (min-width: 2460px) {
  #header nav {
    padding-left: 40px;
  }
}

/* sub header */
#topNav {
  border-bottom: 1px solid #ddd;
  height: 140px;
  width: 1200px;
  margin: 0 auto;
}
#topNav .h1 {
  font-size: 30px;
}

#topNavList li {
  margin-left: 20px;
  opacity: 0.6;
  transition: 0.3s;
}

#topNavList li.active,
#topNavList li:hover {
  opacity: 1;
}

#sNav ul li {
  min-height: 33px;
}
