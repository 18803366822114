.joinWrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-left: 250px;
  box-sizing: border-box;
  height: calc(100vh - 40px);
}

.joinTitle {
  font-weight: 400;
  font-size: 30px;
  color: #000;
  margin-bottom: 10px;
}

.joinWrap p {
  color: #454545;
  margin-bottom: 20px;
}

.joinTitle span {
  color: #033887;
  font-size: 30px;
  /* font-weight:300; */
}

.join {
  /* margin-left: 250px; */
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.big-btn {
  display: flex;
  width: 280px;
  height: 280px;
  border-radius: 0px;
  border: 1px solid #ddd;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}

.big-btn:hover {
  color: #000;
  background: #f5f5f5;
}

.big-btn + .big-btn {
  border-left: 0;
}

.big-btn .user {
  font-size: 24px;
  font-weight: 400;
  color: #000;
  margin-bottom: 5px;
}

.big-btn p {
  color: #454545;
  font-size: 13px;
}

.big-btn .btn {
  width: 100px;
  height: 36px;
  background-color: #05419b;
  border-radius: 0;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 13px;
  margin-top: 25px;
}

.big-btn:hover .btn {
  background-color: #022f72;
}

.big-btn .btn02 {
  background-color: #03877e;
}

.big-btn:hover .btn02 {
  background-color: #026b64;
}

.register_head {
  margin-top: 0px;
}
