@charset "utf-8";

/* 기본박스 */
.mbskin {
    position: relative;
    margin: 140px auto 0;
    width: 640px;
    text-align: center
}

.mbskin:after {
    right: 10px;
    left: auto;
    -webkit-transform: skew(8deg) rotate(3deg);
    -moz-transform: skew(8deg) rotate(3deg);
    -ms-transform: skew(8deg) rotate(3deg);
    -o-transform: skew(8deg) rotate(3deg);
    transform: skew(8deg) rotate(3deg)
}

.mbskin .mbskin_box {
    border: 1px solid #dde7e9;
    background: #fff
}

.mbskin .frm_input {
    width: 100%
}

.mbskin .btn_submit {
    width: 100%;
    margin: 10px 0 0;
    height: 45px;
    font-weight: bold;
    font-size: 1.25em
}

.mbskin strong {
    padding-left: 40px;
    font-size: 24px;
    margin-top: 80px;
    display: block;
}


.mbskin .mbskin_box {
    border-radius: 4px;
}

#login_fs .btn_submit {
    background: #17a2b8;
}

#joinLink {
    color: #343a40;
    font-size: 13px;
    opacity: 0.6;
}

#joinLink a {
    font-size: 13px;
    border: 1px solid #343a40;
}

#mb_login #login_fs {
    padding-top: 10px
}

#mb_login #login_password_lost {
    font-size: 13px;
    color: #343a40;
    border: 1px solid #343a40;
}

#mb_login #login_password_lost:hover {
    color: #fff
}

.login_btn {
    margin: 40px auto 20px;
    font-weight:bold !important;
}


/* 아이디/비밀번호 찾기 */
#find_info #mb_hp_label {
    display: inline-block;
    margin-left: 10px
}

#find_info p {
    line-height: 1.5em
}

#find_info #mb_email {
    margin: 10px 0
}

/* 로그인 */
#mb_login {}

#mb_login h1 {
    position: absolute;
    font-size: 0;
    line-height: 0;
    overflow: hidden
}

#login_fs {
    padding: 35px
}

#mb_login #login_fs .frm_input {
    margin: 0 0 10px
}

#mb_login #login_fs .btn_submit {
    margin: 0 0 15px
}

#login_info {}

#login_info:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: ""
}

#login_info .login_if_auto {
    float: left
}

#login_info .login_if_auto label {
    vertical-align: baseline;
    padding-left: 5px
}

#login_info .login_if_lpl {
    float: right
}

#login_password_lost {
    display: inline-block;
}

#mb_login_notmb {
    margin: 30px auto;
    padding: 20px 30px;
    border: 1px solid #dde7e9
}

#mb_login_notmb h2 {
    font-size: 1.25em;
    margin: 20px 0 10px
}

#guest_privacy {
    border: 1px solid #ccc;
    text-align: left;
    line-height: 1.6em;
    color: #666;
    background: #fafafa;
    padding: 10px;
    height: 150px;
    margin: 10px 0;
    overflow-y: auto
}

#mb_login_notmb .btn_submit {
    display: block;
    text-align: center;
    line-height: 45px
}

#mb_login_od_wr {
    margin: 30px auto;
    padding: 20px 30px;
    border: 1px solid #dde7e9
}

#mb_login_od_wr h2 {
    font-size: 1.25em;
    margin: 20px 0 10px
}

#mb_login_od_wr .frm_input {
    margin: 10px 0 0
}

#mb_login_od_wr p {
    background: #f3f3f3;
    margin: 20px 0 0;
    padding: 15px 20px;
    line-height: 1.5em
}

#mb_login #sns_login {
    margin-top: 0;
    border-color: #edeaea;
    padding: 25px
}

#mb_login #sns_login:after {
    display: block;
    visibility: hidden;
    clear: both;
    content: ""
}

#mb_login #sns_login h3 {
    position: absolute;
    font-size: 0;
    line-height: 0;
    overflow: hidden
}

#mb_login #sns_login .sns-wrap {
    margin: 0 !important
}

#mb_login #sns_login .sns-icon {
    width: 49% !important;
    float: left !important
}

#mb_login #sns_login .sns-icon:nth-child(odd) {
    margin-right: 2%
}

#mb_login #sns_login .txt {
    font-size: 0.95em;
    padding-left: 5px !important;
    border-left: 0 !important
}



/* 회원 비밀번호 확인 */
#mb_confirm {}

#mb_confirm h1 {
    margin: 60px 0 30px;
    font-size: 2em
}

#mb_confirm p {
    padding: 0 20px 40px;
    border-bottom: 1px solid #e9e9e9;
    font-size: 1.083em;
    line-height: 1.4em;
    color: #656565
}

#mb_confirm p strong {
    display: block;
    color: #3ca1ff;
    font-size: 1.167em;
    margin: 0 0 5px
}

#mb_confirm fieldset {
    padding: 50px;
    text-align: left;
}

#mb_confirm fieldset .frm_input {
    background-color: #fff !important
}

#mb_confirm label {
    letter-spacing: -0.1em
}

#mb_confirm_id {
    display: block;
    margin: 5px 0 10px;
    font-weight: bold
}

#mb_confirm .confirm_id {
    font-size: 0.92em;
    color: #666
}



/* 폼메일 */
#formmail textarea {
    height: 100px
}

#formmail .formmail_flie {
    position: relative
}

#formmail .formmail_flie .file_wr {
    border: 1px solid #ccc;
    background: #fff;
    color: #000;
    vertical-align: middle;
    border-radius: 3px;
    padding: 5px;
    height: 40px;
    margin: 0
}

#formmail .lb_icon {
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 3px 0 0 3px;
    height: 38px;
    line-height: 38px;
    width: 40px;
    background: #fff;
    text-align: center;
    color: #b2b2b2
}

#formmail .frm_file {
    padding-left: 50px
}

#formmail .frm_info {
    color: #3497d9;
    font-size: 0.92em
}

.chk_box {
    position: relative
}

.chk_box input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    outline: 0;
    z-index: -1;
    overflow: hidden
}

.chk_box input[type="radio"]+label {
    position: relative;
    padding-left: 23px;
    display: inline-block;
    color: #676e70
}

.chk_box input[type="radio"]+label span {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    display: block;
    background: #f7f8f9;
    border: 1px solid #cdd6df;
    border-radius: 50%
}

.chk_box input[type="radio"]:checked+label {
    color: #3a8afd
}

.chk_box input[type="radio"]:checked+label span {
    border-color: #3a8afd
}

.chk_box input[type="radio"]:checked+label span:before {
    width: 7px;
    height: 7px;
    background: #3a8afd;
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%
}

.chk_box input[type="checkbox"]+label {
    position: relative;
    color: #676e70
}

.chk_box input[type="checkbox"]+label:hover {
    color: #2172f8
}

.chk_box input[type="checkbox"]+label span {
    float: left;
    width: 15px;
    height: 15px;
    display: block;
    background: #fff;
    border: 1px solid #d0d4df;
    border-radius: 3px
}

.chk_box input[type="checkbox"]:checked+label {
    color: #000
}

.chk_box input[type="checkbox"]:checked+label span {
    /* background: url(./img/chk.png) no-repeat 50% 50% #3a8afd; */
    background: no-repeat 50% 50% #3a8afd;
    border-color: #1471f6;
    border-radius: 3px
}

.selec_chk {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    outline: 0;
    z-index: -1;
    overflow: hidden
}
