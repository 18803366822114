@charset "UTF-8";

.box1 {
  padding: 40px;
  background: #f3f6fa;
}

a:hover {
  text-decoration: none;
}

#worksheet .main_modal h4 {
  /*font-family: "Jua", sans-serif;*/
  font-family: "Noto Sans KR";
  line-height: 1.5;
  font-weight: 500;
  font-size: 18px;
}

#worksheet .minwidth {
  padding-bottom: 80px;
  /* min-height: 100vh; */
}

#worksheet .header {
  width: 100%;
  background: #f3f6fa;
  position: relative;
  z-index: 99;
}

#worksheet .header ul {
  height: 140px;
}

#worksheet .nav li ~ li {
  margin-left: 100px;
}

#worksheet .header ul a {
  font-size: 18px;
  letter-spacing: -0.25px;
  font-weight: 600;
  color: #666666;
  padding: 0 0 6px 0;
}

#worksheet .header ul.nav {
  position: relative;
}

#worksheet .header ul.nav::after {
  position: absolute;
  top: 0;
  left: calc(50% - 500%);
  /* left:0; */
  background: #f3f6fa;
  height: 140px;
  transition: all 0.3s;
  content: "";
  width: 1000%;
  display: block;
  z-index: -1;
}

#worksheet .header ul.nav:hover::after {
  height: 350px;
}

#worksheet .header ul.nav a.active,
#worksheet .header ul.nav > li > a:hover {
  border-bottom: 2px solid #033887;
  color: #003887;
}

.header .logo img {
  width: 180px;
  display: inline-block;
}

#worksheet .nav > li {
  position: relative;
}

#worksheet .nav .sub-nav {
  opacity: 0;
  visibility: hidden;
  padding-top: 20px;
  position: absolute;
  width: 150%;
  z-index: 100;
  top: 20px;
  transition: all 0.3s;
  left: calc(50% - 75%);
}

#worksheet .nav .sub-nav li {
  padding: 0;
  margin: 0;
  text-align: center;
}

#worksheet .nav .sub-nav li a {
  font-size: 13px;
  display: Block;
  font-weight: normal;
  padding: 3px 0;
}

#worksheet .nav .sub-nav li a:hover {
  color: #033887;
}

#worksheet .header::after {
  position: absolute;
  top: 0;
  left: 0;
  background: #f3f6fa;
  height: 140px;
  transition: all 0.3s;
  content: "";
  width: 100%;
  display: block;
  z-index: -1;
}
/* #worksheet .header:hover::after, */
#worksheet .header.on::after {
  height: 350px;
}

#worksheet .nav:hover .sub-nav,
#worksheet .nav.on .sub-nav {
  opacity: 1;
  visibility: visible;
}

#worksheetNav {
  top: calc(50% - 120px);
  z-index: 20;
}
#worksheetNav.fixed-right {
  left: inherit;
  right: 0;
  top: calc(50% - 80px);
}

#worksheetNav a {
  display: block;
  width: 80px;
  height: 80px;
  background: #003887;
  color: #fff;
  font-size: 14px !important;
  padding: 10px;
  word-break: keep-all;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid #fff;
}

#worksheetNav a:hover {
  background: #333;
  color: #ddd;
}

#worksheet div.btn {
  padding: 0;
}

#worksheet div.btn a {
  padding: 8px 14px;
}

.btnArea .btn a {
  color: #fff;
  font-size: 14px;
}

.btnArea button.btn {
  font-size: 14px;
  font-weight: 500;
}

.btnArea .btn:hover a {
  color: #033887;
}

.disabled {
  background: #ddd;
}

.fileupload {
  background: #eee;
  border-radius: 3px;
  padding: 8px 0;
}

.youtube {
  height: 100px;
  background: #eee;
  font-size: 16px;
}

.youtube h2 {
  font-size: 18px;
}

.youtube a {
  font-size: 16px;
  border-radius: 3px;
}

.dataForm,
.beforeDateForm {
  font-size: 15px;
}

.dataForm .section {
  margin-bottom: 40px;
}

.question {
  margin-bottom: 20px;
}

.answer ul {
  list-style: none;
}

.answer li {
  height: 40px;
  line-height: 1;
}

.answer li.h-0 {
  height: 0;
}

.answer label span {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #ddd;
  position: relative;
  margin-right: 8px;
}

.answer label:hover {
  cursor: pointer;
}

.answer li span {
  cursor: pointer;
}

.answer label.radio span {
  border-radius: 50%;
}

.answer label.radio span::after {
  display: block;
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  left: calc(50% - 9px);
  top: calc(50% - 9px);
  border-radius: 50%;
}

.answer label.checkbox span {
  width: 16px;
  height: 16px;
  border: 1px solid #ddd;
  /* background-image: url('../img/check.svg'); */
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #fff;
  background-size: 10px;
  top: 3px;
}

.answer .inputTextForm {
  background: #f3f6fa;
  padding: 8px 10px;
  border: 0;
  border-radius: 3px;
  width: 100%;
}

.answer .textareaForm {
  background: #f3f6fa;
  padding: 8px 10px;
  border: 0;
  border-radius: 3px;
  width: 100%;
  /* min-height: 142px; */
  min-height: 123px;
  overflow: hidden;
  font-size: 14px;
}

.answer .textareaForm .scroll {
  overflow: scroll;
}

.canvas .textareaForm {
  height: 100%;
  font-size: 14px;
}

.textareaForm.required,
.inputForm.required {
  position: relative;
  overflow: hidden;
}

.textareaForm.required:after,
.inputForm.required:after {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  content: "*";
  color: red;
}

.comment .question {
  font-size: 18px;
  font-weight: 800;
}

.comment .answer textarea {
  min-height: 160px;
  background: #fff5ed;
  font-size: 14px;
}

.stepTable {
  border-top: 2px solid #033887;
  border-bottom: 1px solid #dee2e6;
}

.stepTable .bd-right {
  border-right: 1px solid #dee2e6;
}

.stepTable .bg {
  background: #f3f6fa;
}

.stepTable .center {
  text-align: center;
}

.stepTable .middle {
  vertical-align: middle;
}

.stepTable .inputForm,
.stepTable .textareaForm {
  border: 0px;
  padding: 8px;
  width: 100%;
}

/* #a_1_1_group > #a_1_1_1:checked ~ .section label.a_1_1_1 span {
  background-color: #033887;
} */

#a_1_1_1:checked ~ .section label.a_1_1_1 span {
  background-color: #033887;
}

#a_1_1_2:checked ~ .section label.a_1_1_2 span {
  background-color: #033887;
}

#a_1_1_3:checked ~ .section label.a_1_1_3 span {
  background-color: #033887;
}

#a_1_1_4:checked ~ .section label.a_1_1_4 span {
  background-color: #033887;
}

#a_1_1_5:checked ~ .section label.a_1_1_5 span {
  background-color: #033887;
}

#a_1_1_6:checked ~ .section label.a_1_1_6 span {
  background-color: #033887;
}
.section .a_1_1_6_text {
  display: none;
}
#a_1_1_6:checked ~ .section .a_1_1_6_text {
  display: block;
}

#a_1_2_1:checked ~ .section label.a_1_2_1 span {
  background-color: #033887;
}

#a_1_2_2:checked ~ .section label.a_1_2_2 span {
  background-color: #033887;
}

#a_1_2_3:checked ~ .section label.a_1_2_3 span {
  background-color: #033887;
}

#a_1_3_1:checked ~ .section label.a_1_3_1 span {
  background-color: #033887;
}

#a_1_3_2:checked ~ .section label.a_1_3_2 span {
  background-color: #033887;
}

#a_1_6_1:checked ~ .section label.a_1_6_1 span {
  background-color: #033887;
}

#a_1_6_2:checked ~ .section label.a_1_6_2 span {
  background-color: #033887;
}

#a_1_6_3:checked ~ .section label.a_1_6_3 span {
  background-color: #033887;
}

#a_1_8_1:checked ~ .section label.a_1_8_1 span {
  background-color: #033887;
}

#a_1_8_2:checked ~ .section label.a_1_8_2 span {
  background-color: #033887;
}

#a_1_8_3:checked ~ .section label.a_1_8_3 span {
  background-color: #033887;
}

#a_1_8_4:checked ~ .section label.a_1_8_4 span {
  background-color: #033887;
}

#a_1_8_5:checked ~ .section label.a_1_8_5 span {
  background-color: #033887;
}

#a_1_8_6:checked ~ .section label.a_1_8_6 span {
  background-color: #033887;
}

#a_1_9_1:checked ~ .section label.a_1_9_1 span {
  background-color: #033887;
}

#a_1_9_2:checked ~ .section label.a_1_9_2 span {
  background-color: #033887;
}

#a_1_9_3:checked ~ .section label.a_1_9_3 span {
  background-color: #033887;
}

#a_1_9_4:checked ~ .section label.a_1_9_4 span {
  background-color: #033887;
}

#a_1_9_5:checked ~ .section label.a_1_9_5 span {
  background-color: #033887;
}

#a_1_9_6:checked ~ .section label.a_1_9_6 span {
  background-color: #033887;
}

#a_2_5_1:checked ~ .section label.a_2_5_1 span {
  background-color: #033887;
}

#a_2_5_2:checked ~ .section label.a_2_5_2 span {
  background-color: #033887;
}

#a_2_5_3:checked ~ .section label.a_2_5_3 span {
  background-color: #033887;
}

#a_2_6_1:checked ~ .section label.a_2_6_1 span {
  background-color: #033887;
}

#a_2_6_2:checked ~ .section label.a_2_6_2 span {
  background-color: #033887;
}

#a_2_6_3:checked ~ .section label.a_2_6_3 span {
  background-color: #033887;
}

#b_5_4_1:checked ~ .section label.b_5_4_1 span {
  background-color: #033887;
}

#b_5_4_2:checked ~ .section label.b_5_4_2 span {
  background-color: #033887;
}

#b_5_5_1:checked ~ .section label.b_5_5_1 span {
  background-color: #033887;
}

#b_5_5_2:checked ~ .section label.b_5_5_2 span {
  background-color: #033887;
}

#b_5_5_3:checked ~ .section label.b_5_5_3 span {
  background-color: #033887;
}

#b_5_5_4:checked ~ .section label.b_5_5_4 span {
  background-color: #033887;
}

#b_5_6_1:checked ~ .section label.b_5_6_1 span {
  background-color: #033887;
}

#b_5_6_2:checked ~ .section label.b_5_6_2 span {
  background-color: #033887;
}

#b_5_6_3:checked ~ .section label.b_5_6_3 span {
  background-color: #033887;
}

#b_5_6_4:checked ~ .section label.b_5_6_4 span {
  background-color: #033887;
}

#b_5_6_5:checked ~ .section label.b_5_6_5 span {
  background-color: #033887;
}

#b_5_6_6:checked ~ .section label.b_5_6_6 span {
  background-color: #033887;
}

#b_5_6_7:checked ~ .section label.b_5_6_7 span {
  background-color: #033887;
}

#b_7_2_1:checked ~ .section label.b_7_2_1 span {
  background-color: #033887;
}

#b_7_2_2:checked ~ .section label.b_7_2_2 span {
  background-color: #033887;
}

#b_7_2_3:checked ~ .section label.b_7_2_3 span {
  background-color: #033887;
}

#b_7_2_4:checked ~ .section label.b_7_2_4 span {
  background-color: #033887;
}

#b_7_2_5:checked ~ .section label.b_7_2_5 span {
  background-color: #033887;
}

#b_7_4_1:checked ~ .section label.b_7_4_1 span {
  background-color: #033887;
}

#b_7_4_2:checked ~ .section label.b_7_4_2 span {
  background-color: #033887;
}

#b_7_5_1:checked ~ .section label.b_7_5_1 span {
  background-color: #033887;
}

#b_7_5_2:checked ~ .section label.b_7_5_2 span {
  background-color: #033887;
}

.basicWrap {
  height: 100%;
  width: 1200px;
  margin: 0 auto;
}

.last-step {
  min-height: calc(100vh - 300px);
}

.last-step a {
  display: block;
  width: 300px;
  height: 300px;
  background: #033887;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  font-weight: bold;
  margin: 0 40px;
}

.last-step a img {
  display: block;
  width: 100px;
  margin-bottom: 20px;
}

.last-step a:nth-child(2) {
  background: #1d90c9;
}

.last-step a:hover {
  background: #052f6f;
}

.last-step a:nth-child(2):hover {
  background: #29a6e4;
}

#reporttable tr {
  border-top: 1px solid #dee2e6;
}
#reporttable .answer td {
  background: #fff;
}

.otherTable,
.otherTable th,
.otherTable td {
  border: 0;
}

.swot tr th.strengths {
  background: #0079c3;
}

.swot tr th.weaknesses {
  background: #fe8a3d;
}

.swot tr th.opportunities {
  background: #18c053;
}

.swot tr th.threats {
  background: #6767af;
}

.swot h1 {
  font-size: 32px;
  padding: 4px 0;
}

.swot p {
  font-size: 18px;
  padding-bottom: 4px;
}

.swot tr td.strengths {
  background: #eff2fb;
}

.swot tr td.weaknesses {
  background: #fbf3f1;
}

.swot tr td.opportunities {
  background: #f2fbf6;
}

.swot tr td.threats {
  background: #f7f6fc;
}

.otherTable tr td textarea {
  background: transparent;
  min-height: 36vh;
  border: 0;
  width: 100%;
  font-size: 14px;
}

.forces p {
  font-size: 18px;
}

.forces .color1 {
  background: #138271;
}

.forces .color2 {
  background: #85a245;
}

.forces .color3 {
  background: #cc4f3d;
}

.forces .color4 {
  background: #cf9005;
}

.forces .color5 {
  background: #bdbdbd;
}

.forces th:nth-child(1),
.forces td:nth-child(1) {
  text-align: left;
}

.forces th:last-child,
.forces td:last-child,
.forces td:last-child textarea {
  text-align: right;
}

.forces.otherTable tr td textarea {
  min-height: 250px;
}

.industrymap .th {
  background: #ddd;
  text-align: center;
}

.industrymap .th input {
  background: transparent;
  border: 0;
  text-align: center;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
}

.industrymap input {
  width: 90%;
  margin: 10px auto 0;
  display: block;
  padding: 6px 0;
  border: 1px solid #ddd;
  text-align: center;
}

.industrymap button {
  display: block;
  width: 90%;
  margin: 10px auto 0;
}

.pamm.market {
  background: transparent;
  border: 0;
  text-align: center;
  font-size: 15px;
  padding: 10px 0;
  margin: 0;
  width: 100%;
}

#b_7_2_1:checked ~ .section .b_7_2_1_txt {
  display: block !important;
}
#b_7_2_2:checked ~ .section .b_7_2_2_txt {
  display: block !important;
}
#b_7_2_3:checked ~ .section .b_7_2_3_txt {
  display: block !important;
}
#b_7_2_4:checked ~ .section .b_7_2_4_txt {
  display: block !important;
}
#b_7_2_5:checked ~ .section .b_7_2_5_txt {
  display: block !important;
}

#worksheet div.addBtn div {
  padding: 4px 10px;
  background: #033887;
  color: white;
  border: 1px solid white;
}

#worksheet div.addBtn:hover div {
  padding: 4px 10px;
  background: white;
  color: #033887;
  cursor: pointer;
  border: 1px solid #033887;
}

/* 양우님 */
.ddd {
  padding: 0 !important;
}

.ddd .no_padd {
  padding: 0.75rem;
  flex: 1;
}

.ddd {
  padding: 0.75rem;
}

.disabled > .inputForm {
  background-color: #ddd;
}

.tt {
  padding: 10.5px !important;
  flex: 1;
  /* display: table-cell; */
}

.uu {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.zz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worksheet .border {
  border: 1;
}

.invisible {
  visibility: hidden;
}

/*

.answer .w-25 {
  width: 40% !important;
}

.answer .w-25 div {
 margin-bottom:20px;
}

*/

.dataForm .answer.d-flex {
  flex-flow: column;
}

.dataForm .answer.d-flex .w-25.disabled {
  width: 100% !important;
}

.answer .w-25 {
  width: 40% !important;
}

.answer .fileupload.w-25 {
  width: 20% !important;
}

.answer .w-25 div {
  padding: 10px;
}

.worksheet5 .disabled,
.worksheet5 .table thead ~ tbody td.ddd {
  background: #eee;
}

.worksheet5 .table tbody td {
  padding: 0;
}

.worksheet5 .table tbody td > div {
  border-bottom: 1px solid #ddd;
  min-height: 100px;
}
.worksheet5 .table tbody td > div,
.worksheet5 .table thead ~ tbody td.ddd div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.worksheet5 .table tbody:last-child td {
  text-align: center;
  vertical-align: middle;
}
