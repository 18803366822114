@charset "UTF-8";

/* 게시판리스트 */
.bo_sch_wrap {display:none;width:100%;height:100%;position:fixed;top:0;left:0;z-index:999}
.bo_sch {position:absolute;top:50%;left:50%;background:#fff;text-align:left;width:330px;max-height:300px;margin-left:-125px;margin-top:-180px;overflow-y:auto;border-radius:5px;-webkit-box-shadow:1px 1px 18px rgba(0,0,0,0.2);-moz-box-shadow:1px 1px 18px rgba(0,0,0,0.2);box-shadow:1px 1px 18px rgba(0,0,0,0.2);border:1px solid #dde7e9;background:#fff;border-radius:3px}
.bo_sch:after {display:block;visibility:hidden;clear:both;content:""}
.bo_sch h3 {padding:15px;border-bottom:1px solid #e8e8e8}
.bo_sch legend {background:red}
.bo_sch form {padding:15px;display:block}
.bo_sch select {border:0;width:100%;height:40px;border:1px solid #d0d3db;border-radius:2px;-webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
-moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075)}
.bo_sch .sch_bar {display:inline-block;width:100%;clear:both;margin-top:15px;border:1px solid #d0d3db;border-radius:2px;-webkit-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
-moz-box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075);
box-shadow:inset 0 1px 1px rgba(0, 0, 0, .075)}
.bo_sch .sch_input {width:250px;height:38px;border:0;padding:0;background-color:transparent;float:left}
.bo_sch .sch_btn {height:38px;float:right;color:#656565;background:none;border:0;width:40px;font-size:15px}
.bo_sch .bo_sch_cls {position:absolute;right:0;top:0;color:#b5b8bb;border:0;padding:12px 15px;font-size:16px;background:#fff}
.bo_sch_bg {background:#000;background:rgba(0,0,0,0.1);width:100%;height:100%}


/* 게시판 읽기 */
#bo_v {margin-bottom:20px;background:#fff;box-sizing:border-box}
#bo_v_table {position:absolute;top:0;right:16px;margin:0;padding:0 5px;height:25px;background:#ff3061;color:#fff;font-weight:bold;line-height:2.2em}
#bo_v_title {}
#bo_v_title .bo_v_cate {display:inline-block;line-height:20px;background:#e2eaf6;color:#3a8afd;padding:0 10px;border-radius:3px;}
#bo_v_title .bo_v_tit {display:block;margin:5px 0 0;word-break:break-all}
#bo_v_info {margin:0;color:#666}
#bo_v_info:after {display:block;visibility:hidden;clear:both;content:""} 
#bo_v_info h2 {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_info .profile_info {margin:20px 0 10px;display:inline-block;float:left}
#bo_v_info .profile_info .pf_img {float:left;margin-right:10px}
#bo_v_info .profile_info .pf_img img {border-radius:50%;width:50px;height:50px}
#bo_v_info .profile_info .profile_info_ct {float:left;padding:5px 0;line-height:18px}
#bo_v_info strong {display:inline-block;margin:0 10px 0 0;font-weight:normal}
#bo_v_info .sv_member,
#bo_v_info .sv_guest,
#bo_v_info .member,
#bo_v_info .guest {font-weight:bold}
#bo_v_info .profile_img {display:none}
#bo_v_info .sv_member {color:#000}
#bo_v_info .if_date {margin:0;color:#888}
#bo_v_file h2 {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_file ul {margin:0;list-style:none}
#bo_v_file li {padding:15px;position:relative;margin:10px 0;border:1px solid #dfdfdf;border-radius:5px;
-webkit-box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%);
-moz-box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%);
box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%)}
#bo_v_file li i {float:left;color:#b2b2b2;font-size:2.35em;margin-right:20px}
#bo_v_file a {float:left;display:block;text-decoration:none;word-wrap:break-word;color:#000}
#bo_v_file a:focus, #bo_v_file li:hover a, #bo_v_file a:active {text-decoration:underline;color:#3a8afd}
#bo_v_file img {float:left;margin:0 10px 0 0}
#bo_v_file .bo_v_file_cnt {color:#b2b2b2;font-size:0.92em}
#bo_v_file li:hover {border-color:#bed4f4;color:#bed4f4}
#bo_v_file li:hover i {color:#3a8afd}
#bo_v_file li:hover .bo_v_file_cnt {color:#99c2fc}
#bo_v_link h2 {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_file ul {margin:0;list-style:none}
#bo_v_link li {padding:15px;position:relative;margin:10px 0;border:1px solid #dfdfdf;border-radius:5px;
-webkit-box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%);
-moz-box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%);
box-shadow:1px 1px 5px 0px hsl(232, 36%, 96%)}
#bo_v_link li i {float:left;color:#b2b2b2;font-size:2.35em;margin-right:20px}
#bo_v_link a {float:left;display:block;text-decoration:none;word-wrap:break-word;color:#000}
#bo_v_link a:focus, #bo_v_link li:hover a, #bo_v_link a:active {text-decoration:underline;color:#3a8afd}
#bo_v_link .bo_v_link_cnt {color:#b2b2b2;font-size:0.92em}
#bo_v_link li:hover {border-color:#bed4f4;color:#bed4f4}
#bo_v_link li:hover i {color:#3a8afd}
#bo_v_
#bo_v_top {zoom:1}
#bo_v_top:after {display:block;visibility:hidden;clear:both;content:""}
#bo_v_top h2 {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_top ul {padding:0;list-style:none;word-break:break-all;background:#fff}
#bo_v_bot {zoom:1}
#bo_v_bot:after {display:block;visibility:hidden;clear:both;content:""}
#bo_v_bot h2 {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_bot ul {padding:0;list-style:none}
.bo_v_com {margin:20px 0;float:right}
.bo_v_com > li {position:relative;float:left;margin-left:5px}
.bo_v_nb {position:relative;margin:20px 0;clear:both;text-align:left}
.bo_v_nb:after {display:block;visibility:hidden;clear:both;content:""}
.bo_v_nb li {border-top:1px solid #f1f1f1;padding:13px}
.bo_v_nb li:last-child {border-bottom:1px solid #f1f1f1}
.bo_v_nb li:hover {background:#f6f6f6}
.bo_v_nb li i {font-size:13px;color:#b3b3b3}
.bo_v_nb li .nb_tit {display:inline-block;padding-right:20px;color:#b3b3b3}
.bo_v_nb li .nb_date {float:right;color:#b3b3b3}
#bo_v_atc {min-height:200px;height:auto !important;height:200px}
#bo_v_atc_title {position:absolute;font-size:0;line-height:0;overflow:hidden}
#bo_v_img {width:100%;overflow:hidden;zoom:1}
#bo_v_img:after {display:block;visibility:hidden;clear:both;content:""}
#bo_v_img a.view_image {display:block}
#bo_v_img img {margin-bottom:20px;max-width:100%;height:auto}
#bo_v_con {margin:10px 0 30px;width:100%;line-height:1.7em;min-height:200px;word-break:break-all;overflow:hidden}
#bo_v_con a {color:#000;text-decoration:underline}
#bo_v_con img {max-width:100%;height:auto}
#bo_v_act {margin-bottom:30px;text-align:center}
#bo_v_act .bo_v_act_gng {position:relative}
#bo_v_act a {margin-right:5px;vertical-align:middle;color:#4a5158}
#bo_v_act a:hover {background-color:#fff;color:#ff484f;border-color:#ff484f}
#bo_v_act i {font-size:1.4em;margin-right:5px}
#bo_v_act_good, #bo_v_act_nogood {display:none;position:absolute;top:30px;left:0;z-index:9999;padding:10px 0;width:165px;background:#ff3061;color:#fff;text-align:center}
#bo_v_act .bo_v_good {display:inline-block;border:1px solid #dedede;width:70px;line-height:46px;border-radius:30px}
#bo_v_act .bo_v_nogood {display:inline-block;border:1px solid #dedede;width:70px;line-height:46px;border-radius:30px}
#bo_v_sns {padding:0;list-style:none;zoom:1;float:left;display:inline-block}
#bo_v_sns:after {display:block;visibility:hidden;clear:both;content:""}
#bo_v_sns li {float:left;width:135px;margin-right:5px;text-align:left}
#bo_v_sns li a {height:35px;line-height:35px;text-align:center;border-radius:5px;color:#fff;font-size:0.95em}
#bo_v_sns li img {vertical-align:middle;margin-right:5px}
#bo_v_sns li .sns_f {display:block;background:#3b5997}
#bo_v_sns li .sns_t {display:block;background:#09aeee}
#bo_v_sns li .sns_g {display:block;background:#ea4026}
#bo_v_sns li .sns_k {display:block;background:#fbe300}
#bo_v_share {position:relative;padding:20px 0}
#bo_v_share:after {display:block;visibility:hidden;clear:both;content:""}
a.squear-btn-border-basic {font-size:13px; border-color:#666; color:#666; }
