@charset "UTF-8";

/* write */
#bo_w {
  width: 600px;
  margin: auto;
  border-radius: 16px;
  margin-bottom: 40px;
  padding: 60px;
  box-sizing: border-box;
}

#bo_w * {
  box-shadow: none !important;
  -moz-box-shadow: none !important;
}

#bo_w input[type="text"],
#bo_w input[type="password"],
#bo_w input[type="email"],
#bo_w textarea {
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  margin-bottom: 10px;
}

#bo_w input[type="text"],
#bo_w input[type="password"] {
  height: 46px;
  padding: 8px 12px;
  letter-spacing: 0.1px;
}

#bo_w button,
#bo_w input,
#bo_w .btn_submit#bo_w .btn_submit,
#bo_w .btn_cancel {
  font-size: 13px;
}

#captcha {
  border: 0px;
}

#bo_w #wr_content {
  /* background-image: url('../image/require.png') !important; */
  background-repeat: no-repeat !important;
  background-position: right top !important;
  max-height: 200px !important;
}

.p {
  font-size: 14px;
}

/* view */
#bo_v_atc {
  font-size: 15px;
}

#bo_v_atc {
  width: 800px !important;
  margin: 0 auto !important;
}

#bo_v_atc .row:first-child {
  border-top: 2px solid #033887;
}

#bo_v_atc .row {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
}

#bo_v_atc .col-3.text-center {
  border-right: 1px solid #ddd;
}
#bo_v_con {
  min-height: 200px;
}
