.postModal {
  position: absolute;
  top: 0p;
  left: 0px;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  /*  */
  display: none;
}
.post {
  width: 700px;
  height: 400px;
  margin: 0 auto;
  position: relative;
  top: 15%;
}
